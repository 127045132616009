import React from 'react';
import * as echarts from 'echarts';
import './translate.css';
import { socket } from '../../utils/utils.js'
let myChart = null;
let timer = null;
let resizeTimer = null;
export default class Translate extends React.Component {
    state = {
        dataIndex: 0,
        dataLines: {},
    };
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

    }
    componentDidMount () {
        myChart = echarts.init(this.myRef.current);
        socket.emit('fetchTranslate');
        // userCount
        socket.on('translate', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            // 筛出CT总量前9的数据
            const dataLines = {};
            msgs.forEach(msg => {
                const key = `${msg.action}(${msg.key})`;
                if (!dataLines[key]) {
                    dataLines[key] = [];
                }
                dataLines[key].push([msg.HourStr, msg.CT]);
            })

            // 计算每个 key 下所有数组的第二个元素的总和
            const sums = Object.entries(dataLines).map(([key, arr]) => {
                const sum = arr.reduce((total, item) => total + (item[1] || 0), 0);
                return { key, sum };
            });

            // 根据总和由高到低排序
            sums.sort((a, b) => b.sum - a.sum);

            // 选前 9 个
            const top9 = sums.slice(0, 9);
            // 选出前 9 个的 key
            const dataLinesTop9 = top9.map(item => item.key);
            const dataLinesTop9Obj = {};
            dataLinesTop9.forEach(key => {
                dataLinesTop9Obj[key] = dataLines[key];
            });



            this.setState({
                dataLines: dataLinesTop9Obj,
                dataIndex: 0,
            });
        });
        timer = setInterval(this.drawChartFn, 3000)
        // 窗口大小改变时，重新渲染图表
        window.addEventListener('resize', this.resize);

    }
    resize = () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            if (myChart) { // 如果myChart实例存在
                const option = myChart.getOption();
                if (option) {
                    const calculateSizeOpt = this.calculateSize();
                    option.title[0].textStyle.fontSize = calculateSizeOpt.title.fontSize;
                    option.xAxis[0].axisLabel.textStyle.fontSize = calculateSizeOpt.xAxis.axisLabel.textStyle.fontSize;
                    option.grid[0] = calculateSizeOpt.grid;
                    myChart.setOption(option, true);
                }

                myChart.resize(); // 调用myChart的resize方法，重新渲染图表
            }
        }, 200);
    }
    // 计算文字大小的函数
    calculateSize () {
        const clientWidth = document.documentElement.clientWidth;
        const opt = {
            xAxis: {
                axisLabel: {
                    textStyle: {
                        fontSize: 13
                    }
                }
            },
            title: {
                fontSize: 12
            },
            grid: {
                left: 10,
                right: 10,
                top: 40,
                bottom: 20,
            }
        }
        // 设计稿的字体大小，如果画面大于720px,需要根据画面宽度进行缩放
        const base = 1600;
        if (clientWidth > base) {
            opt.xAxis.axisLabel.textStyle.fontSize = opt.xAxis.axisLabel.textStyle.fontSize + (clientWidth - base) / 100;

            opt.title.fontSize = opt.title.fontSize + (clientWidth - base) / 100;
            opt.grid.left = opt.grid.left + (clientWidth - base) / 100;
            opt.grid.right = opt.grid.right + (clientWidth - base) / 100;
            opt.grid.top = opt.grid.top + (clientWidth - base) / 100;
            opt.grid.bottom = opt.grid.bottom + (clientWidth - base) / 100;
        }
        return opt;
    }
    // 销毁
    componentWillUnmount () {
        if (myChart) { // 如果myChart实例存在
            myChart.dispose(); // 销毁实例
        }
        socket.off('translate');
        clearInterval(timer);
        window.removeEventListener('resize', this.resize);
    }
    drawChartFn = () => {
        var dataLineKeys = Object.keys(this.state.dataLines);
        var dataIndex = this.state.dataIndex;
        if (dataIndex >= dataLineKeys.length) {
            dataIndex = 0;
        }
        var title = dataLineKeys[dataIndex];
        this.setState({
            dataIndex: dataIndex + 1,
        });
        this.drawChart(myChart, title);
    }
    drawChart (myChart, title) {
        if (!title) {
            return;
        }
        const calculateSizeOpt = this.calculateSize();
        var option = {
            title: {
                text: `時間帯別の利用状況:${title}`,
                textStyle: {
                    color: '#FFF',
                    fontSize: calculateSizeOpt.title.fontSize
                },
            },
            grid: calculateSizeOpt.grid,
            xAxis: {
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: '#c8cdd8',
                        fontSize: calculateSizeOpt.xAxis.axisLabel.textStyle.fontSize
                    },
                },
                axisLine: {
                    lineStyle: {
                        color: '#326482',
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: '#326482',
                    }
                },
                splitNumber: 8,
                max: 24,
            },
            yAxis: {
                show: false,
                type: 'value',
            },
            series: [
                {
                    id: 'a',
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        color: '#dec41a',
                    },
                    data: this.state.dataLines[title],
                }
            ]
        };
        ;
        if (option && typeof option === "object") {
            myChart.setOption(option, true);
        }

    }
    render () {
        return (
            <div className="row">
                <div className="col-lg-12 translate-info">
                    <div className="xpanel" >
                        <div ref={this.myRef} className="translate-graph"></div>
                    </div>
                </div>
            </div>
        );
    }
};
