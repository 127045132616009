import React from 'react'
import './map.css'

import geoJson from './geojson/japan.json'
import cloneDeep from 'lodash/cloneDeep'
import * as echarts from 'echarts'
import { socket } from '../../utils/utils.js'

const option = require('./option.js')

export default class Map extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      echarts: echarts,
      geoJson: cloneDeep(geoJson),
      myChart: null,
      data: [],
      Address: '',
    }
    if (props.area) {
      const geojson = require('./geojson')
      // this.state.geoJson.features = geoJson.features.filter(item => item.properties.name === props.area);
      this.state.geoJson.features = geojson.getGeoJson(props.area)
      console.log(props.area, this.state.geoJson.features)
      this.state.echarts.registerMap(props.area, this.state.geoJson)
    } else {
      this.state.echarts.registerMap('japan', this.state.geoJson, {
        Hokkaido: {
          left: 130,
          top: 38,
          width: 5,
        },
        Okinawa: {
          left: 137,
          top: 29.5,
          width: 10,
        },
      })
    }
  }
  setOption() {
    var zoom = this.props.zoom ? this.props.zoom : 11
    var filterAddress = null
    var displayName = null
    if (this.props.area === 'Tokyo') {
      filterAddress = '東京都'
      displayName = '東京23区'
    } else if (this.props.area === 'Kyoto') {
      filterAddress = '京都府'
      displayName = '京都市'
    } else if (this.props.area === 'Osaka') {
      filterAddress = '大阪府'
      displayName = '大阪市'
    } else if (this.props.area === 'Aichi') {
      filterAddress = '愛知県'
      displayName = '名古屋'
    }
    this.setState({ Address: displayName })
    var data = this.state.data.filter(
      (msg) => !filterAddress || msg.address1 === filterAddress
    )
    // console.log("map data", this.props.area, data);
    var o = option.getOption(this.props.area, zoom, data)

    if (o && typeof o === 'object') {
      this.state.myChart.setOption(o, true)
    }
  }
  assignEnglishName(msgs) {
    msgs.forEach((msg) => {
      var msgRelation = this.state.geoJson.features.filter(
        (item) => item.properties.name_jp === msg.address1
      )
      if (msgRelation && msgRelation.length > 0) {
        msg.name_en = msgRelation[0].properties.name
      }
      this.fixForHokkaido(msg)
      this.fixForOkinawa(msg)
    })
  }
  fixForHokkaido(msg) {
    if (msg.name_en != 'Hokkaido') {
      return
    }
    var coord = msg.gis_coord.split(',').reverse()
    msg.coord = [parseFloat(coord[0]) - 9.8, parseFloat(coord[1]) - 3.8]
  }
  fixForOkinawa(msg) {
    if (msg.name_en != 'Okinawa') {
      return
    }
    var coord = msg.gis_coord.split(',').reverse()
    msg.coord = [parseFloat(coord[0]) + 14.97, parseFloat(coord[1]) + 5.87]
  }

  componentDidMount() {
    socket.emit('fetchMerchantUserCount')
    // userCount
    socket.on('merchantUserCount', (msgs) => {
      if (!msgs || msgs.length < 1) {
        return
      }
      this.assignEnglishName(msgs)
      // console.log("assignEnglishName", msgs);
      var myChart = this.state.myChart
        ? this.state.myChart
        : this.state.echarts.init(this.myRef.current)
      this.setState(
        {
          data: msgs,
          myChart: myChart,
        },
        function () {
          this.setOption()
        }
      )
    })
  }
  componentWillUnmount() {
    // 移除socket的merchantUserCount事件监听器
    socket.off('merchantUserCount')

    // 销毁ECharts实例
    if (this.state.myChart) {
      this.state.echarts.dispose(this.state.myChart)
    }
  }
  render() {
    let border
    if (!this.props.area) {
      border = (
        <div>
          <div className="Hokkaido"></div>
          <div className="Okinawa"></div>
        </div>
      )
    } else {
      border = <div></div>
    }
    return (
      <React.Fragment>
        <div className="map-title">{this.state.Address}</div>
        <div
          className="col-lg-12"
          ref={this.myRef}
          style={{ height: '100%' }}
        ></div>
        {border}
      </React.Fragment>
    )
  }
}
