const prefCoord = require('./pref.json');

// 计算文字大小的函数
const calculateSize = (num) => {
    const clientWidth = document.documentElement.clientWidth
    // 设计稿的字体大小，如果画面大于720px,需要根据画面宽度进行缩放
    const base = 1600
    if (clientWidth > base) {
        num =
            num + (clientWidth - base) / 100
    }
    return num
}

var mapStyle = {
    normal: {
        areaColor: '#2e2f55',
        borderColor: '#fefefe'
    },
    emphasis: {
        areaColor: '#f0e62b',
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowBlur: 20,
        borderWidth: 0
    }
};

var geoCenter = {
    'japan': [136.2552703, 36.1965424],
    'Tokyo': [139.755717, 35.6515318],
    'Kyoto': [135.7622431, 35.1154929],
    'Osaka': [135.5341971, 34.5807379],
    'Aichi': [136.9385168, 35.1369453]
};

var resolveData = function (data) {
    if (!data || data.length < 1) { return []; }
    return data.map(item => {
        var coord = item.coord ? item.coord : item.gis_coord.split(',').reverse();
        return {
            name: item.merchant_name,
            value: coord.concat(item.userCount),
            order: item.userCount
        }
    });
}

var convertData = function (area, data) {
    if (!data) { return []; }
    if (area === 'japan') { return []; }
    return resolveData(data).sort(function (a, b) {
        return b.order - a.order;
    });
};

// show the regist user in 86400 second
var convertDataForEffectScatter = function (area, data) {
    if (!data) { return []; }
    var temp = data.filter(item => {
        var date = new Date(item.lastcreatetime * 1000);
        return new Date() - date < 86400000;// 24 * 60 * 60 * 1000;
    });
    if (area === 'japan') {
        var groups = groupPref(temp);
        groups.forEach(group => {
            group.userCount = group.count;
        })
        return resolveData(groups);
    } else {
        return resolveData(temp);
    }
};
var groupPref = function (data) {
    var groups = [];
    data.forEach(item => {
        let group = item.address1;
        groups[group] = groups[group] || [];
        groups[group].push(item);
    });
    var res = [];
    Object.values(groups).forEach(group => {
        var temp = { count: 0 };
        group.forEach(item => {
            temp.address1 = item.address1;
            temp.count++;
            if (!temp.userCount) { temp.userCount = item.userCount }
            else { temp.userCount += item.userCount }
            if (!temp.coord) {
                temp.coord = item.coord;
            }
            if (!temp.gis_coord) {
                temp.gis_coord = item.gis_coord;
            }
            if (!temp.name_en) {
                temp.name_en = item.name_en;
            }
        })
        res.push(temp);
    })
    return res;
}

var regionColors = [
    '#8ef6f6',
    '#7cdef5',
    '#4e9cf4',
    '#1c59f5'
]
// set region colors
var resolveRegions = function (area, data) {
    var regions = groupPref(data);
    var res = regions.map(item => {
        var areaColor = regionColors[0];
        if (item.userCount > 10) {
            areaColor = regionColors[1];
        }
        if (item.userCount > 50) {
            areaColor = regionColors[2];
        }
        if (item.userCount > 100) {
            areaColor = regionColors[3];
        }
        return {
            name: item.name_en,
            itemStyle: {
                areaColor: areaColor
            }
        };
    });
    if (area === 'japan') {
        addLabel(res, 'Hokkaido', '北海道');
        addLabel(res, 'Okinawa', '沖縄県');
    }
    return res;
}

var addLabel = function (res, pref, text) {
    // console.log("res", res);
    var label = {
        formatter: text,
        position: 'right',
        show: true,
        color: '#5dc2fe',
        fontSize: calculateSize(20),
        textBorderColor: '#0B1024',
        textBorderWidth: 2,
        textShadowColor: '#0B1024',
        textShadowBlur: 2,
    }
    var items = res.filter(item => item.name === pref);
    if (!items || items.length < 1) {
        var Hokkaido = {
            name: pref,
            label: label
        }
        res.push(Hokkaido);
    } else {
        items[0].label = label;
    }

}

var dataLines = function (data) {
    var regions = groupPref(data).sort(function (a, b) {
        return b.userCount - a.userCount;
    });
    var res = [];
    regions.forEach(item => {
        var prefInfo = prefCoord.filter(p => p.pref === item.address1);
        if (!prefInfo || prefInfo.length < 1) {
            return;
        }
        var coord = prefInfo[0];
        res.push({
            name: item.address1,
            value: item.userCount,
            coords: [
                [coord.lng, coord.lat],
                [coord.lng + coord.plus.lng, coord.lat + coord.plus.lat]
            ],
        })
    })
    return res;
}

exports.getOption = function (area, zoom, data) {
    if (!area) { area = 'japan' }
    var center = geoCenter[area];
    if (!center) {
        center = geoCenter['japan'];
    }
    // https://www.echartsjs.com/zh/option.html#geo.roam
    var option = {
        geo: {
            roam: false,
            map: area,
            aspectScale: 1,
            silent: true,
            itemStyle: mapStyle,
            selectedMode: 'single',
            center: center,
            regions: resolveRegions(area, data),
            zoom: zoom
        },
        series: [
            {
                type: 'scatter',
                coordinateSystem: 'geo',
                data: convertData(area, data),
                symbolSize: function (val) {
                    var size = val[2] / 20;
                    size = size < 10 ? 10 : size;
                    //  最大值为30
                    return size > 30 ? 30 : size;
                },
                label: {
                    formatter: '{b}',
                    position: 'right',
                    show: false
                },
                itemStyle: {
                    color: '#f0bd26'
                },
                emphasis: {
                    label: {
                        show: true,
                    }
                }
            },
            {
                type: 'scatter',
                coordinateSystem: 'geo',
                data: convertData(area, data).slice(0, 1),
                symbolSize: function (val) {
                    var size = val[2] / 20;
                    size = size < 5 ? 5 : size;
                    //  最大值为30
                    return size > 30 ? 30 : size;
                },
                label: {
                    formatter: '{b}',
                    position: 'right',
                    show: false
                },
                itemStyle: {
                    color: '#f0e62b'
                },
                emphasis: {
                    label: {
                        show: true,
                    }
                }
            },
            {
                type: 'effectScatter',
                coordinateSystem: 'geo',
                data: convertDataForEffectScatter(area, data),
                symbolSize: function (val) {
                    if (area !== 'japan') {
                        return 20;
                    }
                    // console.log("convertDataForEffectScatter", val[2]);
                    var size = 12 + val[2] * 3;
                    return size > 30 ? 30 : size;
                },
                // showEffectOn: 'render',
                // rippleEffect: {
                //     brushType: 'stroke'
                // },
                // hoverAnimation: true,
                label: {
                    formatter: '',
                    position: 'right',
                    show: true
                },
                itemStyle: {
                    color: '#dec41a',
                    shadowBlur: 10,
                    shadowColor: '#333'
                },
                zlevel: 1
            }
        ]
    };
    // add lines
    if (area === 'japan') {
        option.series.push({
            type: 'lines',
            symbol: 'circle',
            symbolSize: [6, 4],
            label: {
                show: true,
                formatter: '{b}{c}人',
                fontSize: calculateSize(14),
                color: '#f0bd26',
                opacity: 1,
            },
            lineStyle: {
                type: 'dotted',
                shadowBlur: 3,
                color: "#f0e62b", //"#f0bd26"
            },
            data: dataLines(data),
        });

    }
    return option;
}