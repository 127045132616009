import React from 'react';
import * as echarts from 'echarts';
import './week.css';
import { socket } from '../../utils/utils.js'
let myChart = null;
let resizeTimer = null;
export default class Week extends React.Component {
    state = {
        hotelCount: '-',
        userCount: '-',
        shareCount: '-',
        openedCount: '-',
    };
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

    }
    componentDidMount () {
        socket.emit('fetchWeekMerchantUserCount');
        // userCount
        socket.on('weekMerchantUserCount', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            this.drawChart(msgs);
        });
        // 窗口大小改变时，重新渲染图表
        window.addEventListener('resize', this.resize);
    }
    // 销毁
    componentWillUnmount () {
        if (myChart) { // 如果myChart实例存在
            myChart.dispose(); // 销毁实例
        }
        socket.off('weekMerchantUserCount');
        window.removeEventListener('resize', this.resize);
    }
    resize = () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            if (myChart) { // 如果myChart实例存在
                const option = myChart.getOption();
                const calculateSizeOpt = this.calculateSize();
                option.title[0].textStyle.fontSize = calculateSizeOpt.title.fontSize;
                option.yAxis[0].axisLabel.textStyle.fontSize = calculateSizeOpt.yAxis.axisLabel.textStyle.fontSize;
                option.series[0].label.fontSize = calculateSizeOpt.series.label.fontSize;
                option.grid[0].left = calculateSizeOpt.grid.left;
                myChart.setOption(option, true);
                myChart.resize(); // 调用myChart的resize方法，重新渲染图表
            }
        }, 200);
    }
    // 计算文字大小的函数
    calculateSize () {
        const clientWidth = document.documentElement.clientWidth;
        const opt = {
            title: {
                fontSize: 12
            },
            yAxis: {
                axisLabel: {
                    textStyle: {
                        fontSize: 16
                    }
                }
            },
            series: {
                label: {
                    fontSize: 14,
                }

            },
            grid: {
                left: 210,
            }
        }
        // 设计稿的字体大小，如果画面大于720px,需要根据画面宽度进行缩放
        const base = 1600;
        if (clientWidth > base) {
            opt.title.fontSize = opt.title.fontSize + (clientWidth - base) / 100;
            opt.yAxis.axisLabel.textStyle.fontSize = opt.yAxis.axisLabel.textStyle.fontSize + (clientWidth - base) / 100;
            opt.series.label.fontSize = opt.series.label.fontSize + (clientWidth - base) / 100;

            opt.grid.left = opt.grid.left * (clientWidth / base);
        }
        return opt;
    }
    drawChart (data) {
        const source = [['amount', 'product'],];
        data.reverse().forEach(row => {
            source.push([row.CT, row.merchant_name])
        });
        myChart = echarts.init(this.myRef.current);
        var option = null;
        const calculateSizeOpt = this.calculateSize();
        option = {
            title: {
                text: '週間ホテルユーザ数TOP 10',
                textStyle: {
                    color: '#FFF',
                    fontSize: calculateSizeOpt.title.fontSize
                },
            },
            dataset: {
                source: source
            },
            grid: { containLabel: true },
            xAxis: { name: 'amount', show: false },
            yAxis: {
                type: 'category',
                offset: 5,
                axisLabel: {
                    textStyle: {
                        color: '#c8cdd8',
                        fontSize: calculateSizeOpt.yAxis.axisLabel.textStyle.fontSize
                    },
                },
            },
            grid: [{
                left: calculateSizeOpt.grid.left,
                top: 30,
                right: 20,
                bottom: 0
            }],
            series: [
                {
                    type: 'bar',
                    encode: {
                        // Map the "amount" column to X axis.
                        x: 'amount',
                        // Map the "product" column to Y axis
                        y: 'product'
                    },
                    label: {
                        show: true,
                        position: 'right',
                        color: '#c8cdd8',
                        fontSize: calculateSizeOpt.series.label.fontSize
                    },
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                console.log("params", params);
                                var defaultColor = '#1398eb';
                                var colorList = ['#0cd598', '#dec41a', '#f84852',];
                                if (params.dataIndex < 7) {
                                    return defaultColor;
                                }
                                return colorList[params.dataIndex - 7];
                            }
                        },
                    },
                }
            ]
        };
        if (option && typeof option === "object") {
            myChart.setOption(option, true);
        }

    }
    render () {
        return (
            <div className="row">
                <div className="col-lg-12 week-info">
                    <div className="xpanel" >
                        <div ref={this.myRef} className="week-graph"></div>
                    </div>
                </div>
            </div>
        );
    }
};
