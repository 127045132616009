import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
const setRemUnit = () => {
  const designWidth = 1920; // 设计稿宽度
  const remBase = 1; // rem 基准值
  let screenWidth = window.innerWidth; // 屏幕宽度
  if (screenWidth > designWidth) { // 如果屏幕宽度大于设计稿宽度，设置屏幕宽度为设计稿宽度
    screenWidth = designWidth;
  }
  const scale = screenWidth / designWidth; // 缩放比例
  const rootFontSize = scale * remBase; // 计算根元素字体大小
  document.documentElement.style.fontSize = rootFontSize + 'px'; // 设置根元素字体大小
}
setRemUnit();
let timer = null;
window.addEventListener('resize', () => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    setRemUnit();
  }, 500);
});
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
