import React from 'react';
import ReactTextTransition from "react-text-transition";

import './user.css';
import { socket } from '../../utils/utils.js'

export default class User extends React.Component {
    state = {
        shareCount: '0',
        userCount: '0',
        openedCount: '0',
        countAvg: '0',
        userLikedRegistTime: '',
        shareRate: '0',
        avgLiveTime: '0',
        shareUserCount: '0',
    };
    constructor(props) {
        super(props);

    }
    componentDidMount () {
        this.fetchUserCount();
        this.fetchUserLikedRegistTime();
        // this.fetchAvgLiveTime();
        this.fetchShareUserCount();

        socket.emit('fetchUserCount');
        // socket.emit('fetchAvgLiveTime');
        socket.emit('fetchShareUserCount');
        socket.emit('fetchUserLikedRegistTime');
    }
    // 销毁
    componentWillUnmount () {
        socket.off('userCount');
        socket.off('avgLiveTime');
        socket.off('userLikedRegistTime');
        // openedCount
        socket.off('openedCount');
        // shareCount
        socket.off('shareCount');
    }
    fetchUserCount () {
        this.fetchShareRate();
        this.fetchAvgUseCount();
        // userCount
        socket.on('userCount', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            this.setState({
                userCount: msgs[0].userCount,
            }, () => {
                socket.emit('fetchOpenedCount');
                socket.emit('fetchShareCount');
            });
        });
    }
    fetchAvgLiveTime () {
        socket.on('avgLiveTime', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            var msg = msgs[0];
            this.setState({
                avgLiveTime: Math.ceil(msg.avgLiveTime / 60 / 60 / 24)
            });
        });
    }
    fetchShareUserCount () {
        socket.on('shareUserCount', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            var msg = msgs[0];
            this.setState({
                shareUserCount: msg.CT
            });
        });
    }
    fetchUserLikedRegistTime () {
        socket.on('userLikedRegistTime', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            var msg = msgs[0];
            console.log("fetchUserLikedRegistTime", msg);
            this.setState({
                userLikedRegistTime: msg.HourStr
            });
        });

    }
    fetchAvgUseCount () {
        const avgFunc = () => {
            if (this.state.openedCount == 0) {
                return;
            }
            if (this.state.userCount == 0) {
                return;
            }
            this.setState({
                countAvg: Math.ceil(this.state.openedCount / this.state.userCount),
            });
        };
        // openedCount
        socket.on('openedCount', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            var msg = msgs[0];
            this.setState({
                openedCount: msg.CT
            }, avgFunc);
        });
    }
    fetchShareRate () {
        const avgFunc = () => {
            if (this.state.shareCount == 0) {
                return;
            }
            if (this.state.userCount == 0) {
                return;
            }
            this.setState({
                shareRate: Math.floor(this.state.shareCount / this.state.userCount * 100),
            });
        };
        // shareCount
        socket.on('shareCount', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            var msg = msgs[0];
            this.setState({
                shareCount: msg.CT
            }, avgFunc);
        });
    }
    render () {
        return (
            <div className="row">
                <div className="col-lg-12 user-info">
                    <div className="xpanel" >
                        <div className="row">
                            <div className="col-lg-12 user-title">
                                ユーザ統計
                            </div>

                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12 user-description text-lightblue text-ellipsis">平均利用回数</div>
                                    <div className="col-lg-12 user-count">
                                        <ReactTextTransition text={this.state.countAvg} className="text-green count-avg" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12 user-description text-lightblue text-ellipsis">好きな加入タイミング</div>
                                    <div className="col-lg-12 user-count">
                                        <ReactTextTransition text={this.state.userLikedRegistTime} className="text-yellow count-regist-time" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12 user-description text-lightblue text-ellipsis">シェア人数</div>
                                    <div className="col-lg-12 user-count">
                                        <ReactTextTransition text={this.state.shareUserCount} className="text-yellow count-user-share" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12 user-description text-lightblue text-ellipsis">シェア率</div>
                                    <div className="col-lg-12 user-count">
                                        <ReactTextTransition text={this.state.shareRate} className="text-green count-sharerate" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
