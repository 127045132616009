var tokyoJSONs = {
    code: 13,
    name: "Tokyo",
    list: [
        13101,
        13102,
        13103,
        13104,
        13105,
        13106,
        13107,
        13108,
        13109,
        13110,
        13111,
        13112,
        13113,
        13114,
        13115,
        13116,
        13117,
        13118,
        13119,
        13120,
        13121,
        13122,
        13123,
    ]
};
var aichiJSONs = {
    code: 23,
    name: "Aichi",
    list: [
        23101,
        23102,
        23103,
        23104,
        23105,
        23106,
        23107,
        23108,
        23109,
        23110,
        23111,
        23112,
        23113,
        23114,
        23115,
        23116,
    ]
}
var kyotoJSONs = {
    code: 26,
    name: "Kyoto",
    list: [
        26101,
        26102,
        26103,
        26104,
        26105,
        26106,
        26107,
        26108,
        26109,
        26110,
        26111,
    ],
}
var osakaJSONs = {
    code: 27,
    name: "Osaka",
    list: [
        27102,
        27103,
        27104,
        27106,
        27107,
        27108,
        27109,
        27111,
        27113,
        27114,
        27115,
        27116,
        27117,
        27118,
        27119,
        27120,
        27121,
        27122,
        27123,
        27124,
        27125,
        27126,
        27127,
        27128,
        27141,
        27142,
        27143,
        27144,
        27145,
        27146,
        27147,
    ]
}
var JSONs = {
    'Tokyo': tokyoJSONs,
    'Aichi': aichiJSONs,
    'Kyoto': kyotoJSONs,
    'Osaka': osakaJSONs,
}
var geoTemplate = require('./geoTemplate.json');

var getGeoJson = function (JSONs) {
    var features = geoTemplate.features.filter(item => item.properties.name === JSONs.name);
    if (!features || features.length < 1) { // no features
        return features;
    }

    features[0].geometry.type = 'MultiPolygon';
    features[0].geometry.coordinates = [];
    // features.
    JSONs.list.forEach(jsonFile => {
        var json = require(`./${JSONs.code}/${jsonFile}.json`);
        json.features.forEach(feature => {
            features[0].geometry.coordinates.push(feature.geometry.coordinates);
        })
    });
    return features;
}

exports.getGeoJson = function (area) {
    return getGeoJson(JSONs[area]);
};