import React from 'react';
import './app.css';

import { Total, Map, Week, Feature, Translate, User } from './components';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRenderRow: true,
    };
    this.resizeTimeout = null; // 初始化this.resizeTimeout
    this.mapBigRef = React.createRef();
    this.mapBigChildRef = React.createRef();
  }
  componentDidMount () {
    this.handleMapBigResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ shouldRenderRow: false });
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.setState({ shouldRenderRow: true }, () => {
        this.handleMapBigResize();
      });
    }, 100); // 500毫秒后重新渲染row
  };
  handleMapBigResize = () => {
    const mapBig = this.mapBigRef.current;
    const mapBigChild = this.mapBigChildRef.current;
    if (mapBig && mapBigChild) {
      let width = mapBig.offsetWidth;
      let height = width * 800 / 930;
      if (height > mapBig.offsetHeight) {
        height = mapBig.offsetHeight;
        width = height * 930 / 800;
      }
      mapBigChild.style.width = `${width}px`;
      mapBigChild.style.height = `${height}px`;
    }
  };

  render () {
    return (
      <div className="container-fluid">
        <header className="header text-white">
          <h3>QRHOTEL運営データ</h3>
        </header>
        <div className="row">

          <div className="col-lg-3">
            <Feature />
            <Translate />
            <User />
          </div>


          <div className="col-lg-6 pt-100">
            <div className="row">
              <Total />
            </div>
            <div className="map-big" ref={this.mapBigRef}>
              <div className="map-big-child" ref={this.mapBigChildRef}>
                {this.state.shouldRenderRow && (<Map zoom={2} />)}
              </div>
            </div>
          </div>


          <div className="col-lg-3">
            <Week />
            <div className="row">
              <div className="col-sm-6 mapbox">
                <div className="xpanel map-small">
                  {this.state.shouldRenderRow && (<Map area="Tokyo" zoom={1} />)}
                </div>
              </div>
              <div className="col-sm-6 mapbox">
                <div className="xpanel map-small">
                  {this.state.shouldRenderRow && (<Map area="Osaka" zoom={1} />)}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mapbox">
                <div className="xpanel map-small">
                  {this.state.shouldRenderRow && (<Map area="Kyoto" zoom={1} />)}
                </div>
              </div>
              <div className="col-sm-6 mapbox">
                <div className="xpanel map-small">
                  {this.state.shouldRenderRow && (<Map area="Aichi" zoom={1} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
