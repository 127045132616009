import React from 'react';
import * as echarts from 'echarts';
import './feature.css';
import { socket } from '../../utils/utils.js'
let myChart = null;
let resizeTimer = null;
export default class Feature extends React.Component {
    state = {
        hotelCount: '-',
        userCount: '-',
        shareCount: '-',
        openedCount: '-',
    };
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

    }
    componentDidMount () {
        socket.emit('fetchFeatureUsedCount');
        // userCount
        socket.on('featureUsedCount', msgs => {
            if (!msgs || msgs.length < 1) {
                return;
            }
            this.drawChart(msgs);
        });
        // 窗口大小改变时，重新渲染图表
        window.addEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            if (myChart) { // 如果myChart实例存在
                const option = myChart.getOption();
                const calculateSizeOpt = this.calculateSize();
                option.title[0].textStyle.fontSize = calculateSizeOpt.title.fontSize;
                // rich 
                option.series[0].label.rich = calculateSizeOpt.series.rich;
                option.series[0].label.fontSize = calculateSizeOpt.series.fontSize;
                myChart.setOption(option, true);
                myChart.resize(); // 调用myChart的resize方法，重新渲染图表
            }
        }, 200);
    }

    // 销毁
    componentWillUnmount () {
        if (myChart) { // 如果myChart实例存在
            myChart.dispose(); // 销毁实例
        }
        socket.off('featureUsedCount');
        window.removeEventListener('resize', this.handleResize);
    }
    // 计算文字大小的函数
    calculateSize () {
        const clientWidth = document.documentElement.clientWidth;
        const opt = {
            series: {
                rich: {
                    b: {
                        fontSize: 12,
                    },
                    d: {
                        fontSize: 12,
                    },
                },
                fontSize: 12
            },
            title: {
                fontSize: 12
            }
        }
        // 设计稿的字体大小，如果画面大于720px,需要根据画面宽度进行缩放
        const base = 1600;
        if (clientWidth > base) {
            opt.series.rich.b.fontSize = opt.series.rich.b.fontSize + (clientWidth - base) / 100;
            opt.series.rich.d.fontSize = opt.series.rich.d.fontSize + (clientWidth - base) / 100;
            opt.series.fontSize = opt.series.fontSize + (clientWidth - base) / 100;
            opt.title.fontSize = opt.title.fontSize + (clientWidth - base) / 100;
        }
        return opt;
    }
    drawChart (data) {
        myChart = echarts.init(this.myRef.current);
        var option = null;
        const calculateSizeOpt = this.calculateSize();
        option = {
            title: {
                text: '機能利用割合',
                textStyle: {
                    color: '#FFF',
                    fontSize: calculateSizeOpt.title.fontSize
                },
            },
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    radius: ['48%', '60%'],
                    top: '10%',

                    label: {
                        formatter: '{b|{b}：}\n{d|{d}}%',
                        rich: calculateSizeOpt.series.rich,
                        fontSize: calculateSizeOpt.series.fontSize,
                        color: 'inherit',
                    },
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                console.log("params", params);
                                var colorList = ['#1398eb', '#dec41a', '#0cd598', '#e31a1c',];
                                return colorList[params.dataIndex % 4];
                            }
                        },
                    },
                    data: data
                }
            ]
        };
        if (option && typeof option === "object") {
            myChart.setOption(option, true);
        }

    }
    render () {
        return (
            <div className="row">
                <div className="col-lg-12 feature-info">
                    <div className="xpanel" >
                        <div ref={this.myRef} className="feature-graph"></div>
                    </div>
                </div>
            </div>
        );
    }
};
