import React from 'react'
import './total.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faQrcode,
  faShare,
  faHotel,
} from '@fortawesome/free-solid-svg-icons'

import ReactTextTransition from 'react-text-transition'
import { socket } from '../../utils/utils.js'

export default class Total extends React.Component {
  state = {
    hotelCount: '-',
    userCount: '-',
    shareCount: '-',
    openedCount: '-',
  }
  componentDidMount() {
    socket.emit('fetchAll')
    // shareCount
    socket.on('shareCount', (msgs) => {
      if (!msgs || msgs.length < 1) {
        return
      }
      var msg = msgs[0]
      this.setState({
        shareCount: msg.CT,
      })
    })
    // openedCount
    socket.on('openedCount', (msgs) => {
      if (!msgs || msgs.length < 1) {
        return
      }
      var msg = msgs[0]
      this.setState({
        openedCount: msg.CT,
      })
    })
    // userCount
    socket.on('userCount', (msgs) => {
      if (!msgs || msgs.length < 1) {
        return
      }
      console.log('userCount', msgs[0].userCount)
      this.setState({
        userCount: msgs[0].userCount,
      })
    })
    socket.on('merchantCount', (msgs) => {
      if (!msgs || msgs.length < 1) {
        return
      }
      this.setState({
        hotelCount: msgs[0].CT,
      })
    })
  }
  // 销毁
  componentWillUnmount() {
    socket.off('shareCount')
    socket.off('openedCount')
    socket.off('userCount')
    socket.off('merchantCount')
  }
  render() {
    return (
      <div className="col-lg-12 plr-20">
        <div className="row info-total xpanel">
          <div className="col-lg-4 border-right">
            <div className="row">
              <div className="col-lg-12 info-total-description text-lightblue">
                ホテル数
              </div>
              <div className="col-lg-12 info-total-count">
                <ReactTextTransition
                  text={this.state.hotelCount}
                  className="text-green"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 border-right">
            <div className="row">
              <div className="col-lg-12 info-total-description text-lightblue">
                ユーザー数
              </div>
              <div className="col-lg-12 info-total-count">
                <ReactTextTransition
                  text={this.state.userCount}
                  className="text-yellow"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col-lg-12 info-total-description text-lightblue">
                <small>シェア回数</small>
              </div>
              <div className="col-lg-12 info-total-count">
                <ReactTextTransition
                  text={this.state.shareCount}
                  className="text-red"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
